<template>
  <div>
    <!-- <v-row v-if="!product_id">
      <v-col cols="12">
        <h3>{{ $t('Common.food_modifiers') }}</h3>
      </v-col>
    </v-row>-->

    <v-row v-if="list">
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <span class="body-1">Available Modifiers</span>
          </v-card-title>

          <v-card-actions>
            <v-row align="center">
              <v-col cols="12" md="7" lg="6">
                <!-- button goes back to product edit page if product exists -->
                <v-btn small color="info" to="/product/modifier/new">
                  <v-icon left>add</v-icon>New modifier
                </v-btn>
                <v-btn
                  small
                  color="success"
                  @click="saveModifiers"
                  class="ml-1"
                >
                  Save orders
                </v-btn>
              </v-col>
              <v-col cols="12" md="5" lg="6">
                <v-text-field
                  v-model="searchText"
                  append-icon="search"
                  label="Search modifiers..."
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-actions>
          <v-data-table
            :headers="headers"
            :items="list"
            :search="searchText"
            :items-per-page="10"
            item-key="name"
            class="elevation-1"
          >
            <template v-slot:body="props">
              <draggable
                :disabled="searchText.length > 0"
                :list="props.items"
                tag="tbody"
                :move="moved"
                @change="changeOrder"
              >
                <tr v-for="(modifier, index) in props.items" :key="index">
                  <td>
                    <v-icon small class="handle-icon">mdi-arrow-all</v-icon>
                  </td>
                  <td>{{ modifier.name }}</td>
                  <td>
                    <div class="d-flex">
                      <v-btn
                        icon
                        text
                        color="primary"
                        @click="addToProduct(modifier.id)"
                      >
                        <v-icon small>add</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        text
                        color="success"
                        :to="'/product/modifier/' + modifier.id"
                      >
                        <v-icon small>edit</v-icon>
                      </v-btn>
                    </div>
                  </td>
                  <td>
                    <v-menu open-on-hover bottom offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="success" v-bind="attrs" v-on="on" icon>
                          <v-icon>mdi-information</v-icon>
                        </v-btn>
                      </template>
                      <v-card class="pa-1" min-width="280">
                        <v-card-subtitle>Options:</v-card-subtitle>
                        <v-list-item
                          v-for="subItem in modifier.options"
                          :key="subItem.name"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              ><small>{{
                                subItem.name
                              }}</small></v-list-item-title
                            >
                          </v-list-item-content>
                          <v-spacer></v-spacer>
                          <small>{{ subItem.available ? '' : 'NA' }}</small>
                          <v-list-item-action>
                            <small
                              ><span>{{
                                subItem.price | currency
                              }}</span></small
                            >
                          </v-list-item-action>
                        </v-list-item>
                      </v-card>
                    </v-menu>
                  </td>
                </tr>
              </draggable>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" color="success" :timeout="4000">
      Saved Modifiers order!
    </v-snackbar>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
export default {
  props: {
    product_id: {
      Type: Object
    }
  },
  components: {
    Draggable
  },
  data() {
    return {
      snackbar: false,
      list: null,
      tempList: null,
      searchText: '',
      selected: [],
      expanded: [],
      headers: [
        { text: '', value: '' },
        { text: 'Name', value: 'name', sortable: false, align: 'start' },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          filterable: false
        },
        { text: 'Options', filterable: false, sortable: false }
      ]
    }
  },
  mounted() {
    this.loadModifiers()
  },
  methods: {
    changeOrder() {
      this.list = this.tempList.map((item, k) => ({ ...item, no: k }))
    },
    moved(e) {
      this.tempList = e.relatedContext.list
    },
    loadModifiers() {
      this.$store.dispatch('loadModifiers').then(list => {
        this.list = list.sort((a, b) => a.no - b.no)
      })
    },
    saveModifiers() {
      const that = this
      this.$store.dispatch('saveModifiers', this.list).then(() => {
        this.snackbar = true
        that.loadModifiers()
      })
    },
    addToProduct(sel) {
      this.selected = [...this.selected, sel]
      this.$emit('onSelected', this.selected)
    }
  }
}
</script>
<style>
.handle-icon {
  cursor: pointer;
}
</style>
