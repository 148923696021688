<template>
  <v-container fluid v-if="!loading">
    <v-row justify="space-between" v-if="!$route.params.id">
      <v-col cols="4">
        <div class="text-h5">
          {{ $t('Common.food_modifiers') }}
        </div>
      </v-col>
      <v-col cols="6">
        <span class="body-2"
          >Select the checkbox in order to add the modifier to the product.
        </span>
      </v-col>
    </v-row>
    <v-row class=" space-between">
      <v-col cols="12" sm="6" md="5">
        <modifiers
          :product_id="$route.params.id"
          @onSelected="onSelected"
        ></modifiers>
      </v-col>
      <v-col sm="1"> </v-col>

      <v-col cols="12" sm="5">
        <v-row>
          <v-card>
            <v-card-title>
              <span class="body-1">Selected Modifiers</span>
            </v-card-title>

            <v-data-table
              :headers="headers"
              :items="
                modifiers
                  ? Object.entries(modifiers).map(([key, value]) => ({
                      id: key,
                      required: value.min > 0,
                      ...value
                    }))
                  : []
              "
              :items-per-page="10"
              item-key="name"
              class="elevation-1"
            >
              <template v-slot:body="props">
                <draggable
                  :disabled="searchText.length > 0"
                  :list="props.items"
                  tag="tbody"
                  :move="moved"
                  @change="changeOrder"
                >
                  <tr v-for="(modifier, index) in props.items" :key="index">
                    <td>
                      <v-icon small class="handle-icon">mdi-arrow-all</v-icon>
                    </td>
                    <td>{{ modifier.name }}</td>
                    <td>
                      <template v-if="modifier.required">
                        <b>Required</b>
                      </template>
                      <template v-else>
                        <i>Optional</i>
                      </template>
                    </td>
                    <td>
                      <v-btn icon>
                        <v-icon color="red" @click="deleteModifier(modifier.id)"
                          >delete</v-icon
                        >
                      </v-btn>
                    </td>
                  </tr>
                </draggable>
              </template>
            </v-data-table>
          </v-card>
          <v-row class="ma-2">
            <v-col cols="12" md="6" lg="4">
              <v-btn color="success" @click="save">Save Updates</v-btn>
            </v-col>
            <v-col cols="6" md="4">
              <v-btn @click="$router.go(-1)" v-if="$route.params.id">
                <v-icon left>keyboard_backspace</v-icon>Go Back to Dish
              </v-btn>
            </v-col>
          </v-row>
        </v-row>
      </v-col>
    </v-row>
    <!-- <template v-if="dish">
      {{ updateModifiers() }}
    </template> -->
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Modifiers from './ProductModifiers'
import Draggable from 'vuedraggable'

export default {
  components: {
    Modifiers,
    Draggable
  },
  data() {
    return {
      modifiers: null,
      current: null,
      searchText: '',

      tempList: null,
      headers: [
        {
          text: 'Arrange',
          align: 'start'
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },

        {
          text: 'Required',
          value: 'required',
          sortable: true,
          align: 'start',
          filterable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          filterable: false
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      modifier_list: 'modifiers',
      dish: 'dish'
    })
  },
  async mounted() {
    await this.$store.dispatch('getDishById', { id: this.$route.params.id })
    if (this.dish) {
      this.modifiers = this.dish.modifiers
      this.current = this.dish.modifiers
      console.log(this.modifiers, 'loading modifiers!')
    }
  },
  /*   watch: {
    modifiers: function() {
    }
  }, */

  methods: {
    onSelected(selected) {
      this.modifiers = {}
      if (this.current) {
        this.modifiers = this.current
      }
      if (selected) {
        //delete this.modifiers[selected]
        selected.forEach((item, k) => {
          if (item) {
            let a = this.modifier_list.filter(m => m.id === item)
            // options should be fetched from the original list
            // chef may add more option to modifiers later
            //delete a[0].options
            this.modifiers[item] = a[0]
            this.modifiers[item].sort_no = k
          }
        })
      }
    },
    /*  updateModifiers() {
      console.log('Sure')
      this.modifiers = this.dish.modifiers
    }, */
    deleteModifier(id) {
      console.log(this.modifiers, ' deleteing', id)
      delete this.modifiers[id]
      // this.modifiers = this.modifiers.filter(m => m.id !== id)
      this.save()
    },
    save() {
      if (Array.isArray(this.modifiers)) {
        this.modifiers = this.modifiers.reduce(function(map, obj) {
          map[obj.id] = obj
          return map
        }, {})
        console.log('Convert to map')
      }
      console.log(this.modifiers, 'to save modifiers')
      this.$store.dispatch('saveProductModifiers', {
        id: this.$route.params.id,
        modifiers: this.modifiers
      })
    },
    changeOrder() {
      this.modifiers = this.tempList.map((item, k) => ({
        ...item,
        no: k,
        sort_no: k
      }))
    },
    moved(e) {
      this.tempList = e.relatedContext.list
    }
  }
}
</script>
