var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('v-container',{attrs:{"fluid":""}},[(!_vm.$route.params.id)?_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"text-h5"},[_vm._v("\n        "+_vm._s(_vm.$t('Common.food_modifiers'))+"\n      ")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"body-2"},[_vm._v("Select the checkbox in order to add the modifier to the product.\n      ")])])],1):_vm._e(),_c('v-row',{staticClass:" space-between"},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"5"}},[_c('modifiers',{attrs:{"product_id":_vm.$route.params.id},on:{"onSelected":_vm.onSelected}})],1),_c('v-col',{attrs:{"sm":"1"}}),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-row',[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"body-1"},[_vm._v("Selected Modifiers")])]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.modifiers
                ? Object.entries(_vm.modifiers).map(function (ref) {
                                var key = ref[0];
                                var value = ref[1];

                                return (Object.assign({}, {id: key,
                    required: value.min > 0},
                    value));
})
                : [],"items-per-page":10,"item-key":"name"},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c('draggable',{attrs:{"disabled":_vm.searchText.length > 0,"list":props.items,"tag":"tbody","move":_vm.moved},on:{"change":_vm.changeOrder}},_vm._l((props.items),function(modifier,index){return _c('tr',{key:index},[_c('td',[_c('v-icon',{staticClass:"handle-icon",attrs:{"small":""}},[_vm._v("mdi-arrow-all")])],1),_c('td',[_vm._v(_vm._s(modifier.name))]),_c('td',[(modifier.required)?[_c('b',[_vm._v("Required")])]:[_c('i',[_vm._v("Optional")])]],2),_c('td',[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteModifier(modifier.id)}}},[_vm._v("delete")])],1)],1)])}),0)]}}],null,false,770060832)})],1),_c('v-row',{staticClass:"ma-2"},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.save}},[_vm._v("Save Updates")])],1),_c('v-col',{attrs:{"cols":"6","md":"4"}},[(_vm.$route.params.id)?_c('v-btn',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("keyboard_backspace")]),_vm._v("Go Back to Dish\n            ")],1):_vm._e()],1)],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }